import { Box, Container, Typography } from "@mui/material";
import React from "react";
import "./appleprivacy.css";
function ApplePrivacyPolicy() {
  return (
    <>
      {/* <Container maxWidth={"xl"}> */}
      <div className="privacy-container">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "20vh",
            width: "100%",
            margin: "10px 0",
          }}
        >
          <Box
            sx={{
              minHeight: "15vh",
              width: "100%",
              backgroundColor: "#DDDDDD",
              fontSize: "13px",
            }}
          >
            <Typography variant={"h4"}>Privacy Policy</Typography>
          </Box>
          <Typography sx={{ minHeight: "5vh", width: "100%" }}>
           
          </Typography>
        </Box>

        <Box
          sx={{
            minHeight: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // border: "1px solid black",
            margin: "20px 0",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={"h6"} sx={{ color: "#2F8ECC" }}>
          1- INFORMATION WE COLLECT
          </Typography>
          <Typography>
          In Short: We collect personal information that you provide to us. We
            collect personal information that you voluntarily provide to us when
            you register for the SNS PLAYER app, when you participate in
            activities using the app, or otherwise when you contact us. This
            information includes: Images you upload for dynamic display content.
            Email address and name if you contact us via email. Sensitive
            Information: We do not process sensitive information. Payment Data:
            We may collect data necessary to process payments, such as your
            payment instrument number, and the security code associated with
            your payment instrument. All payment data is stored by our payment
            processors. Their privacy policies can be found at their respective
            websites.
          </Typography>
        </Box>

        <Box
          sx={{
            minHeight: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // border: "1px solid black",
            margin: "20px 0",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={"h6"} sx={{ color: "#2F8ECC" }}>
            2-PROCESSING YOUR INFORMATION
          </Typography>
          <Typography>
            In Short: We process your information to provide, improve, and
            administer our services, communicate with you, and ensure security
            and fraud prevention. We process your information to deliver the
            services provided by the SNS PLAYER app, for customer support, and
            to enhance your user experience. We do not collect any sensitive
            personal data.
          </Typography>
        </Box>

        <Box
          sx={{
            minHeight: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // border: "1px solid black",
            margin: "20px 0",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={"h6"} sx={{ color: "#2F8ECC" }}>
            3-LEGAL BASES FOR PROCESSING
          </Typography>
          <Typography>
            In Short: We process your information based on legal grounds. We
            rely on the following legal bases to process your personal
            information: Consent: You have given us explicit consent to process
            your personal information for specific purposes. Contractual
            Obligation: Processing is necessary to fulfill a contract with you
            or to take steps at your request before entering into a contract.
            Legal Obligation: We are required to process your information in
            compliance with legal obligations. Legitimate Interests: We process
            your information when it is necessary for our legitimate interests,
            provided that these interests do not override your rights and
            interests.
          </Typography>
        </Box>

        <Box
          sx={{
            minHeight: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // border: "1px solid black",
            margin: "20px 0",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={"h6"} sx={{ color: "#2F8ECC" }}>
            4-YOUR PRIVACY RIGHTS
          </Typography>
          <Typography>
            In Short: You have specific rights regarding your personal
            information, depending on your location. You have the right to
            access, update, or delete the information we have on you. You may
            also have rights to data portability and restriction of processing.
            Requests can be made directly through our app or by contacting us.
          </Typography>
        </Box>

        <Box
          sx={{
            minHeight: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // border: "1px solid black",
            margin: "20px 0",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={"h6"} sx={{ color: "#2F8ECC" }}>
            5-SECURITY MEASURES
          </Typography>
          <Typography>
            In Short: We take security seriously and implement measures to
            protect your information. We employ security practices like
            encryption and secure server hosting to safeguard your data from
            unauthorized access, alteration, and misuse.
          </Typography>
        </Box>

        <Box
          sx={{
            minHeight: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // border: "1px solid black",
            margin: "20px 0",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={"h6"} sx={{ color: "#2F8ECC" }}>
            6-DATA RETENTION
          </Typography>
          <Typography>
            In Short: We retain personal information for as long as necessary to
            fulfill the purposes outlined in this policy. We store personal
            information as long as it is necessary for the operation of our app
            and to comply with our legal obligations.
          </Typography>
        </Box>

        <Box
          sx={{
            minHeight: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // border: "1px solid black",
            margin: "20px 0",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={"h6"} sx={{ color: "#2F8ECC" }}>
            7-DATA TRANSFERS
          </Typography>
          <Typography>
            In Short: We transfer personal information in compliance with legal
            standards. If your data is transferred internationally, we ensure
            that adequate safeguards are in place to protect your information as
            detailed in this privacy policy.
          </Typography>
        </Box>

        <Box
          sx={{
            minHeight: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // border: "1px solid black",
            margin: "20px 0",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={"h6"} sx={{ color: "#2F8ECC" }}>
            8-UPDATES TO THIS NOTICE
          </Typography>
          <Typography>
            In Short: We update this policy as needed to stay compliant with
            legal changes. We may modify this privacy policy periodically. The
            most current version will always be posted on our app.
          </Typography>
        </Box>

        <Box
          sx={{
            minHeight: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // border: "1px solid black",
            margin: "20px 0",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={"h6"} sx={{ color: "#2F8ECC" }}>
            9-CONTACT US
          </Typography>
          <Typography>
            For questions about this privacy notice, please contact us via email
            at contact@snsgroupe.fr or by mail at: SNS Groupe 11 avenue du Val
            de Fontenay Fontenay-sous-bois 94120 France
          </Typography>
        </Box>
      </div>
      {/* </Container> */}
    </>
  );
}

export default ApplePrivacyPolicy;
